<template>
  <div>
    <h2>Stützweite</h2>
    <p>Die Stützweite der VMM-Decke ergibt sich überschlägig aus dem lichten Abstand der sie stützenden Wände zzgl. jeweils einem Drittel der Wandbreiten.</p>
  </div>
</template>

<script>
export default {
  name: "DimensionsHelp"
};
</script>

<style lang="scss">
</style>